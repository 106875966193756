import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  initBookingPayload,
  setTravelDatePayload,
  setPricingTravelIdPayload,
  setTravellersPayload,
  setChildrenPayload,
  setFamilyPackagePayload,
  setAccommodationSelectionPayload,
  loadContingentsPayload,
  setAccommodationAddonSelectionPayload,
  setPersonalAddonSelectionPayload,
  applyMandatoryBookingAddonsPayload,
  setBookingAddonSelectionPayload,
  setCollectiveAddonSelectionPayload,
  reloadPricingTravelPayload,
  upgradeBookingToExtensionDatePayload,
  downgradeBookingFromExtensionDatePayload,
  switchBookingExtensionDatePayload,
  bookingStateItem,
  setTravellerAddressPayload,
  setBabyAddressPayload,
  setBillingAddressPayload,
  setTravellerRemarksPayload,
  loadBookingSummaryPayload,
  setPaymentMethodPayload,
  setBookingRemarksPayload,
  setMarketingInformationPayload,
  setTravelDocumentationServicePayload,
  setFamilyInsurancePayload,
  setTravellerInsurancePayload,
  setBabiesPayload,
  submitBookingPayload,
  setBookingFatalErrorPayload,
  resetBookingStatePayload,
  setBookingLastActivityTimestampPayload,
  setCurrentBookingStepPayload,
  setBusStopSelectionPayload,
  ToggleWaitlistFallbackAvailabilityStatusPayload,
  setBookingEntryPointPayload,
  setBookingCustomDataPayload,
  setPersonalDiscountSelectionPayload,
  initBookingPaymentPayload,
  setCO2CompensationPayload,
  setBookingEsimServicePayload,
  setCustomBookingParamsPayload,
} from '@/features/booking/types'
import travelAvailabilityStatusObject from '@/entities/travelAvailabilityStatus/interface'
import graphQLClient from '@/lib/api/graphQLClient'
import {
  ALL_AVAILABILITY_STATUS_QUERY,
  RATIO_SUMMARY_QUERY,
  CONTINGENTS_QUERY,
  TRAVEL_QUERY,
  PRICING_TRAVEL_QUERY,
  SUBMIT_BOOKING,
  INIT_BOOKING_PAYMENT_MUTATION,
} from '@/features/booking/gql'
import { sendCheckoutPurchaseEvent } from '@/lib/ga/gtag'
import { getApiPayloadFromBooking } from '@/features/booking/helpers'
import travelPricingAgeGroupObject from '@/entities/travelPricingAgeGroup/interface'
import { sendTrackingEvent } from '@/integrations/tracking/useTracking'

export const setBookingEntryPoint = createAction<setBookingEntryPointPayload>('booking/setBookingEntryPoint')
export const initBooking = createAsyncThunk('booking/initBooking', async (payload: initBookingPayload, thunkAPI) => {
  // @ts-ignore
  const booking = thunkAPI.getState().bookings.find((booking) => {
    return booking.travel && booking.travel.id === payload.travelId
  })
  let travel
  let bargainConfig
  let esimConfig
  let onlinePaymentConfig
  let defaultAvailabilityStatus
  let defaultWaitlistFallbackAvailabilityStatus

  let reload: boolean
  if (!booking) {
    reload = true
  } else {
    // reload if data is older than 30 seconds
    reload = Date.now() - Number(booking.travelTimestamp) > 30 * 1000
    defaultAvailabilityStatus = booking.defaultAvailabilityStatus
    defaultWaitlistFallbackAvailabilityStatus = booking.defaultWaitlistFallbackAvailabilityStatus
  }

  if (booking && !reload) {
    travel = booking.travel
    bargainConfig = booking.bargainConfig
    esimConfig = booking.esimConfig
    onlinePaymentConfig = booking.onlinePaymentConfig
  } else {
    // load all availabilityStatus
    const allAvailabilityStatus: travelAvailabilityStatusObject[] = await graphQLClient
      .request(ALL_AVAILABILITY_STATUS_QUERY)
      .then((response) => response.allTravelAvailabilityStatus)
    defaultAvailabilityStatus = allAvailabilityStatus.find((availabilityStatus) => {
      return availabilityStatus.isDefaultStatus
    })
    defaultWaitlistFallbackAvailabilityStatus = allAvailabilityStatus.find((availabilityStatus) => {
      return availabilityStatus.isDefaultWaitlistFallback
    })

    // load travel
    const response = await graphQLClient
      .request(TRAVEL_QUERY, { travelId: payload.travelId, tenantId: process.env.NEXT_PUBLIC_TENANT_ID })
      .then((response) => {
        return { travel: response.travel, ratioConfig: response.ratioConfig, tenant: response.tenant }
      })
    travel = response.travel
    travel.pricing.ageGroupSettings.ageGroups.sort((a: travelPricingAgeGroupObject, b: travelPricingAgeGroupObject) => b.minAge - a.minAge)
    bargainConfig = response.ratioConfig.bargainConfig
    esimConfig = response.ratioConfig.esimConfig
    onlinePaymentConfig = response.tenant.onlinePaymentConfig
  }

  return {
    travel: travel,
    bargainConfig: bargainConfig,
    esimConfig: esimConfig,
    onlinePaymentConfig: onlinePaymentConfig,
    hasTransportTypes: travel.pricing.travels.length > 1,
    isReloaded: reload,
    defaultAvailabilityStatus: defaultAvailabilityStatus,
    defaultWaitlistFallbackAvailabilityStatus: defaultWaitlistFallbackAvailabilityStatus,
  }
})
export const setTravelDate = createAction<setTravelDatePayload>('booking/setTravelDate')
export const setPricingTravelId = createAsyncThunk('booking/setPricingTravel', async (payload: setPricingTravelIdPayload, thunkAPI) => {
  // @ts-ignore
  const booking = thunkAPI.getState().bookings.find((booking: bookingStateItem) => {
    return booking.travel && booking.travel.id === payload.travelId
  })

  return {
    travelId: payload.travelId,
    isReloaded: !(booking && booking.pricingTravel && booking.pricingTravel.id === payload.pricingTravelId),
    pricingTravel:
      booking && booking.pricingTravel && booking.pricingTravel.id === payload.pricingTravelId
        ? booking.pricingTravel
        : await graphQLClient.request(PRICING_TRAVEL_QUERY, { ratioTravelId: payload.pricingTravelId }).then((response) => response.ratioTravel),
  }
})
export const reloadPricingTravel = createAsyncThunk('booking/reloadPricingTravel', async (payload: reloadPricingTravelPayload, thunkAPI) => {
  // @ts-ignore
  const booking = thunkAPI.getState().bookings.find((booking) => {
    return booking.travel && booking.travel.id === payload.travelId
  })

  // reload if data is older than 30 seconds
  const reload: boolean = Date.now() - booking.pricingTravelTimestamp > 30 * 1000

  return {
    travelId: payload.travelId,
    isReloaded: reload,
    pricingTravel: !reload
      ? booking.pricingTravel
      : await graphQLClient.request(PRICING_TRAVEL_QUERY, { ratioTravelId: booking.pricingTravel.id }).then((response) => response.ratioTravel),
  }
})
export const toggleWaitlistFallbackAvailabilityStatus = createAction<ToggleWaitlistFallbackAvailabilityStatusPayload>(
  'booking/toggleWaitlistFallbackAvailabilityStatus'
)
export const setCustomBookingParams = createAction<setCustomBookingParamsPayload>('booking/setCustomBookingParams')
export const setTravellers = createAction<setTravellersPayload>('booking/setTravellers')
export const setChildren = createAction<setChildrenPayload>('booking/setChildren')
export const setBabies = createAction<setBabiesPayload>('booking/setBabies')
export const setTravellerAddress = createAction<setTravellerAddressPayload>('booking/setTravellerAddress')
export const setBabyAddress = createAction<setBabyAddressPayload>('booking/setBabyAddress')
export const setBillingAddress = createAction<setBillingAddressPayload>('booking/setBillingAddress')
export const setTravellerRemarks = createAction<setTravellerRemarksPayload>('booking/setTravellerRemarks')
export const setBookingRemarks = createAction<setBookingRemarksPayload>('booking/setBookingRemarks')
export const setFamilyPackage = createAction<setFamilyPackagePayload>('booking/setFamilyPackage')
export const setAccommodationSelection = createAction<setAccommodationSelectionPayload>('booking/setAccommodationSelection')
export const loadContingents = createAsyncThunk('booking/loadContingents', async (payload: loadContingentsPayload, thunkAPI) => {
  // @ts-ignore
  const booking = thunkAPI.getState().bookings.find((booking) => {
    return booking.travel && booking.travel.id === payload.travelId
  })

  // reload if data is older than 1 minute
  let reload = false
  if (!booking.contingentTimestamp) {
    reload = true
  }
  if (Date.now() - booking.contingentTimestamp > 60 * 1000) {
    reload = true
  }
  const current = booking.contingents

  // load both contingent types
  return {
    travelId: payload.travelId,
    contingents:
      booking.pricingTravel && booking.travelDate && reload
        ? await graphQLClient
            .request(CONTINGENTS_QUERY, { ratioTravelId: booking.pricingTravel.id, dateId: booking.travelDate.id })
            .then((response) => {
              return response
            })
        : current,
  }
})
// export const loadExtensionContingents = createAsyncThunk(
//   'booking/loadExtensionContingents',
//   async (payload: loadExtensionContingentsPayload, thunkAPI) => {
//     // @ts-ignore
//     const booking = thunkAPI.getState().bookings.find((booking) => {
//       return booking.travel && booking.travel.id === payload.travelId
//     })
//
//     // reload if data is older than 1 minute
//     let reload = false
//     if (!booking.extensionContingentsTimestamp) {
//       reload = true
//     }
//     if (Date.now() - booking.extensionContingentsTimestamp > 5 * 1000) {
//       reload = true
//     }
//
//     let contingents = booking.extensionContingents
//     if (booking.pricingTravel && booking.travelDate && reload) {
//       const manager = new LocalBooking(booking)
//       const dates = manager.getExtensionDates()
//       if (dates.length) {
//         const requests = dates.map((date) => {
//           return graphQLClient.request(CONTINGENTS_QUERY, { ratioTravelId: booking.pricingTravel.id, dateId: date.id }).then((response) => {
//             return { dateId: date.id, packages: response.packages, services: response.services }
//           })
//         })
//
//         await Promise.all(requests).then((values) => {
//           contingents = values
//         })
//       }
//     }
//
//     // load both contingent types
//     return {
//       travelId: payload.travelId,
//       contingents: contingents,
//     }
//   }
// )
export const setAccommodationAddonSelection = createAction<setAccommodationAddonSelectionPayload>('booking/setAccommodationAddonSelection')
export const setPersonalAddonSelection = createAction<setPersonalAddonSelectionPayload>('booking/setPersonalAddonSelection')
export const setBookingAddonSelection = createAction<setBookingAddonSelectionPayload>('booking/setBookingAddonSelection')
export const setCollectiveAddonSelection = createAction<setCollectiveAddonSelectionPayload>('booking/setCollectiveAddonSelection')
export const applyMandatoryBookingAddons = createAction<applyMandatoryBookingAddonsPayload>('booking/applyMandatoryAddons')
export const setPersonalDiscountSelection = createAction<setPersonalDiscountSelectionPayload>('booking/setPersonalDiscountSelection')
export const upgradeBookingToExtensionDate = createAction<upgradeBookingToExtensionDatePayload>('booking/upgradeBookingToExtensionDate')
export const switchBookingExtensionDate = createAction<switchBookingExtensionDatePayload>('booking/switchBookingExtensionDate')
export const downgradeBookingFromExtensionDate = createAction<downgradeBookingFromExtensionDatePayload>('booking/downgradeBookingFromExtensionDate')
export const loadBookingSummary = createAsyncThunk(
  'booking/loadBookingSummary',
  async (payload: loadBookingSummaryPayload, thunkAPI) => {
    // @ts-ignore
    const booking = thunkAPI.getState().bookings.find((booking) => {
      return booking.travel && booking.travel.id === payload.travelId
    })
    const summary: any = await graphQLClient.request(RATIO_SUMMARY_QUERY, { clientState: getApiPayloadFromBooking(booking) }).then((response) => {
      return response.ratioSummary.json
    })

    return {
      travelId: payload.travelId,
      summary: summary,
    }
  },
  {
    condition: (payload: loadBookingSummaryPayload, { getState }) => {
      // @ts-ignore
      const booking = getState().bookings.find((booking) => {
        return booking.travel && booking.travel.id === payload.travelId
      })
      if (booking.ratioSummary || booking.ratioSummaryIsLoading) {
        // already loaded or fetch in progress -> cancel
        return false
      }
    },
  }
)
export const setFamilyInsurance = createAction<setFamilyInsurancePayload>('booking/setFamilyInsurance')
export const setTravellerInsurance = createAction<setTravellerInsurancePayload>('booking/setTravellerInsurance')
export const setBusStopSelectionOutbound = createAction<setBusStopSelectionPayload>('booking/setBoardingSelection')
export const setBusStopSelectionReturn = createAction<setBusStopSelectionPayload>('booking/setBusStopSelectionReturn')
export const setBookingEsimService = createAction<setBookingEsimServicePayload>('booking/setBookingEsimService')
export const setTravelDocumentationService = createAction<setTravelDocumentationServicePayload>('booking/setTravelDocumentationService')
export const setBookingCustomData = createAction<setBookingCustomDataPayload>('booking/setBookingCustomData')
export const setPaymentMethod = createAction<setPaymentMethodPayload>('booking/setPaymentMethod')
export const setCO2Compensation = createAction<setCO2CompensationPayload>('booking/setCO2Compensation')
export const setMarketingInformation = createAction<setMarketingInformationPayload>('booking/setMarketingInformation')
export const submitBooking = createAsyncThunk(
  'booking/submitBooking',
  async (payload: submitBookingPayload, thunkAPI) => {
    // @ts-ignore
    const booking = thunkAPI.getState().bookings.find((booking) => {
      return booking.travel && booking.travel.id === payload.travelId
    })

    // send tracking event
    const data = {
      catalogCode: booking.pricingTravel.catalogCode,
      travelCode: booking.pricingTravel.travelCode,
      travelName: booking.travel.name,
      noOfTravellers: booking.travellers.length,
    }
    sendTrackingEvent.bookingFunnelSubmit(data)

    // submit booking
    const response = await graphQLClient
      .request(SUBMIT_BOOKING, { tenantId: Number(process.env.NEXT_PUBLIC_TENANT_ID), json: getApiPayloadFromBooking(booking) })
      .then((response) => {
        return response.placeBooking
      })

    if (response.success === true) {
      function getBillingAddress(booking: bookingStateItem) {
        const billingTravellers = booking.travellers.filter((traveller) => {
          return traveller.isBillingAddress
        })
        return billingTravellers.length ? billingTravellers[0] : booking.billingAddress
      }
      try {
        const data = {
          catalogCode: booking.pricingTravel.catalogCode,
          travelCode: booking.pricingTravel.travelCode,
          travelName: booking.travel.name,
          orderNo: response.orderNo,
          totalPrice: response.totalPrice,
          noOfTravellers: booking.travellers.length,
        }
        sendCheckoutPurchaseEvent(data)
        sendTrackingEvent.bookingFunnelSuccess(data)

        const billingAddress = getBillingAddress(booking)
        sendTrackingEvent.identify({
          id: response.bookerId,
          data: {
            gender: billingAddress.salutation == 'MR' ? 'm' : 'f',
            birthYear: new Date(billingAddress.birthday).getFullYear(),
            country: billingAddress.country,
          },
        })
      } catch (error) {}
    }

    return {
      travelId: payload.travelId,
      bookingId: response.success ? response.bookingId : null,
      orderNo: response.success ? response.orderNo : null,
      success: response.success,
    }
  },
  {
    condition: (payload: submitBookingPayload, { getState }) => {
      // @ts-ignore
      const booking = getState().bookings.find((booking) => {
        return booking.travel && booking.travel.id === payload.travelId
      })
      if (booking.isSubmitting) {
        // already submitting -> cancel
        return false
      }
    },
  }
)
export const initBookingPayment = createAsyncThunk(
  'booking/initBookingPayment',
  async (payload: initBookingPaymentPayload, thunkAPI) => {
    // @ts-ignore
    const booking = thunkAPI.getState().bookings.find((booking) => {
      return booking.travel && booking.travel.id === payload.travelId
    })
    try {
      const response = await graphQLClient.request(INIT_BOOKING_PAYMENT_MUTATION, {
        input: { bookingId: booking.bookingId, tenantId: process.env.NEXT_PUBLIC_TENANT_ID },
      })
      return {
        travelId: payload.travelId,
        transactionId: response.initBookingPayment.transactionId,
      }
    } catch (error) {
      return {
        travelId: payload.travelId,
        transactionId: '',
      }
    }
  },
  {
    condition: (payload: initBookingPaymentPayload, { getState }) => {
      // @ts-ignore
      const booking = getState().bookings.find((booking) => {
        return booking.travel && booking.travel.id === payload.travelId
      })
      if (booking.payment.initalizing) {
        // already initializing -> cancel
        return false
      }
    },
  }
)

export const setBookingFatalError = createAction<setBookingFatalErrorPayload>('booking/setBookingFatalError')
export const resetBookingState = createAction<resetBookingStatePayload>('booking/resetBookingState')
export const setBookingLastActivityTimestamp = createAction<setBookingLastActivityTimestampPayload>('booking/setBookingLastActivityTimestamp')

export const setCurrentBookingStep = createAction<setCurrentBookingStepPayload>('booking/setCurrentBookingStep')
