import ratioTravelDateObject from '@/entities/ratioTravelDate/interface'
import ratioTravelObject from '@/entities/ratioTravel/interface'
import travelObject from '@/entities/travel/interface'
import { getToday, toUiDate } from '@/lib/helpers/helpers'
import { TFunction } from 'next-i18next'
import { TravelFilterTravel, TravelFilterTravelDate } from '@/features/travelFilter/types'

export const buildTravelDatesLabel = (pricingTravels: ratioTravelObject[], t: TFunction) => {
  let dates = getTravelDisplayRelevantDates(getAllTravelDates(pricingTravels))

  // build label
  let label = ''
  if (dates.length) {
    const firstDate = dates[0]
    label += '<span class="text-nowrap">'
    if (firstDate.startDate == firstDate.endDate) {
      label += `${toUiDate(firstDate.startDate)}`
    } else {
      label += `${toUiDate(firstDate.startDate)} - ${toUiDate(firstDate.endDate)}`
    }
    label += '</span>'
  }

  if (dates.length > 1) {
    label += ` <span class="text-nowrap">${t('travel.andMoreDates')}</span>`
  }

  return label
}

export const getTravelDisplayRelevantDates = (dates: Array<ratioTravelDateObject | TravelFilterTravelDate>) => {
  // omit extension dates & order by startDate
  dates = orderByStartDate(
    dates.filter((date) => {
      // never show extension dates
      return !date.includesExtension
    })
  )

  // filter past dates if more than 1 date
  if (dates.length > 1) {
    const lastDate = dates[dates.length - 1]
    dates = filterPastDates(dates)
    if (!dates.length) {
      dates.push(lastDate)
    }
  }

  return dates
}

const getAllTravelDates = (pricingTravels: ratioTravelObject[]) => {
  let dates: ratioTravelDateObject[] = []
  pricingTravels.forEach((travel) => {
    dates = dates.concat(travel.dates)
  })
  return dates
}

const filterPastDates = (dates: Array<ratioTravelDateObject | TravelFilterTravelDate>) => {
  const today = getToday()
  return dates.filter((date) => {
    return new Date(date.startDate) >= today
  })
}

export const orderByStartDate = (dates: Array<{ startDate: string }>): any[] => {
  function compare(a: { startDate: string }, b: { startDate: string }) {
    const aDate = new Date(a.startDate).getTime()
    const bDate = new Date(b.startDate).getTime()
    if (aDate < bDate) {
      return -1
    }
    if (aDate > bDate) {
      return 1
    }
    return 0
  }

  dates.sort(compare)
  return dates
}

export const buildFilterTravelDatesLabel = (travel: TravelFilterTravel, t: TFunction) => {
  let label: string
  if (travel.startDate == travel.endDate) {
    label = `${toUiDate(travel.startDate)}`
  } else {
    label = `${toUiDate(travel.startDate)} - ${toUiDate(travel.endDate)}`
  }
  let dates = travel.dates.filter((date) => {
    return !date.includesExtension
  })

  if (dates.length > 1) {
    label += `\n${t('travel.andMoreDates')}`
  }
  return label
}

export const getTravelRedirectUrl = (travel: travelObject): string | undefined => {
  const tenantSettings = travel.tenantSettings.find((tenantSettingsObject) => {
    return (
      tenantSettingsObject.tenant?.id === process.env.NEXT_PUBLIC_TENANT_ID ||
      // @ts-ignore
      (tenantSettingsObject.tenantId && tenantSettingsObject.tenantId === process.env.NEXT_PUBLIC_TENANT_ID)
    )
  })

  if (!tenantSettings) {
    return undefined
  }

  if (tenantSettings.redirectToBooking) {
    return `/booking/${travel.seoSettings.urlSlug}`
  }
}

export const isTravelOnline = (travel: travelObject, includeHidden = false, ignoreRedirected = false) => {
  if (!travel?.tenantSettings.length) {
    return false
  }
  const tenantSettings = travel.tenantSettings.find((tenantSettingsObject) => {
    return (
      tenantSettingsObject.tenant?.id === process.env.NEXT_PUBLIC_TENANT_ID ||
      // @ts-ignore
      (tenantSettingsObject.tenantId && tenantSettingsObject.tenantId === process.env.NEXT_PUBLIC_TENANT_ID)
    )
  })

  // no settings -> offline
  if (!tenantSettings) {
    return false
  }

  // OFFLINE
  if (tenantSettings.onlineStatus === 'OFFLINE') {
    return false
  }

  // HIDDEN
  if (!includeHidden) {
    if (tenantSettings.onlineStatus === 'HIDDEN') {
      return false
    }
  }

  // REDIRECT
  if (ignoreRedirected && tenantSettings.redirectToBooking) {
    return false
  }

  if (tenantSettings.useTiming) {
    const now = new Date()
    const offset = now.getTimezoneOffset()
    const todayTime = new Date(now.getTime() - offset * 60 * 1000).getTime()

    // check startDate
    if (tenantSettings.startDate && new Date(tenantSettings.startDate).getTime() > todayTime) {
      return false
    }

    // check endDate
    if (tenantSettings.endDate) {
      // add 1 day, because the end date is included in the range
      const endDate = new Date(tenantSettings.endDate)
      endDate.setDate(endDate.getDate() + 1)
      if (endDate.getTime() <= todayTime) {
        return false
      }
    }
  }

  return true
}

export const isTravelIndexable = (travel: travelObject) => {
  return isTravelOnline(travel, false)
}
