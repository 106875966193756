import { TrackingEventArgs, Tracker, TrackingIdentifyArgs } from '@/integrations/tracking/types'
import posthog from '@/integrations/tracking/posthog/PostHogClient'

export const posthogTracker: Tracker = {
  sendEvent: ({ category, object, action, data }: TrackingEventArgs) => {
    posthog.capture(`${category}:${object}:${action}`, data)
  },
  identify: ({ id, data }: TrackingIdentifyArgs) => {
    posthog.identify(id, data)
  },
}
