import React from 'react'
import useMatomo from '@/integrations/tracking/matomo/useMatomo'
import posthog from '@/integrations/tracking/posthog/PostHogClient'
import { PostHogProvider } from 'posthog-js/react'
import AppTrackingProviderInner from '@/integrations/tracking/AppTrackingProvider/AppTrackingProviderInner'

interface Props {
  children: React.ReactNode
}

const AppTrackingProvider = ({ children }: Props) => {
  useMatomo()

  return (
    <PostHogProvider client={posthog}>
      <AppTrackingProviderInner>{children}</AppTrackingProviderInner>
    </PostHogProvider>
  )
}

export default AppTrackingProvider
