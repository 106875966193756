import trackers from '@/integrations/tracking/trackers'
import { BookingFunnelEventData } from '@/integrations/tracking/events/booking/types'

const category = 'booking_funnel'

export const bookingFunnelEvents = {
  // after selecting travel date and number of travellers, clicking on [book now] button
  bookingFunnelStart: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_start',
        action: 'click',
        data,
      })
    })
  },

  // on reaching step 1 (accommodation/services)
  bookingFunnelStepServices: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_1_services',
        action: 'view',
        data,
      })
    })
  },

  // on reaching step 1 (accommodation/services)
  bookingFunnelStepExtensionUpsell: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_1.5_extension_upsell',
        action: 'view',
        data,
      })
    })
  },

  // on reaching step 2 (personal data)
  bookingFunnelStepPersonalData: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_2_personal_data',
        action: 'view',
        data,
      })
    })
  },

  // on reaching step 3 (insurance & extras)
  bookingFunnelStepAdditionalServices: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_3_additional_services',
        action: 'view',
        data,
      })
    })
  },

  // on reaching step 4 (select payment method)
  bookingFunnelStepSelectPaymentMethod: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_4_select_payment_method',
        action: 'view',
        data,
      })
    })
  },

  // on reaching step 4.5 (payment)
  bookingFunnelStepInitOnlinePayment: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_step_4_payment_selection',
        action: 'view',
        data,
      })
    })
  },

  // on booking submit | click on [book now] button
  bookingFunnelSubmit: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_submit',
        action: 'click',
        data,
      })
    })
  },

  // on booking success | booking confirmed
  bookingFunnelSuccess: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_success',
        action: 'view',
        data,
      })
    })
  },

  // on booking error | error in any step
  bookingFunnelError: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_error',
        action: 'view',
        data,
      })
    })
  },

  // on booking timeout | timeout in any step
  bookingFunnelTimeout: (data: BookingFunnelEventData) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: 'booking_timeout',
        action: 'view',
        data,
      })
    })
  },
}
