import { Tracker } from '@/integrations/tracking/types'
import { posthogTracker } from '@/integrations/tracking/posthog/posthogTracker'
import { matomoTracker } from '@/integrations/tracking/matomo/matomoTracker'

const trackers: Tracker[] = []

if (typeof window !== 'undefined') {
  trackers.push(...[posthogTracker, matomoTracker])
}
export default trackers
