import { TrackingEventArgs, Tracker, TrackingIdentifyArgs } from '@/integrations/tracking/types'
import { push } from '@socialgouv/matomo-next'

export const matomoTracker: Tracker = {
  sendEvent: ({ category, object, action, data }: TrackingEventArgs) => {
    push(['trackEvent', category, `${action}:${object}`, JSON.stringify(data)])
  },
  identify: ({ id }: TrackingIdentifyArgs) => {
    push(['setUserId', id])
  },
}
