require('intersection-observer')
import AppHeadContent from '@/components/template/AppHeadContent'
import 'intl-pluralrules'
import dynamic from 'next/dynamic'
import '/src/styles/main.scss'
import type { AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import * as gtag from '@/lib/ga/gtag'
import { IconSvgSprite } from '@/components/elements/Icon'
import { appWithTranslation } from 'next-i18next'
import AppTrackingProvider from '@/integrations/tracking/AppTrackingProvider'
import SSRProvider from 'react-bootstrap/SSRProvider'
import graphQLClient from '@/lib/api/graphQLClient'
import SWRConfig from '@/lib/api/SWRConfig'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from '@/app/store'
import { saveState } from '@/app/browserStorage'
import { debounce } from 'ts-debounce'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import muiTheme from '@/styles/muiTheme'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode, useEffect } from 'react'
import tenantCmsObject from '@/entities/tenantCms/interface'
import { PrivacyPolicyPageUrlContext, GtcPageUrlContext } from '@/app/context'
import AppConfigProvider from '@/app/providers/AppConfigProvider'
import CmsProvider from '@/app/providers/CmsProvider'
import appConfig from '@/app/config'
import { NextPage } from 'next'
const DefaultLayout = dynamic(() => import('@/layouts/DefaultLayout'))

// subscribe to the store changes and save to localStorage
store.subscribe(
  debounce(() => {
    saveState(store.getState())
  }, 0)
)

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type Props = AppProps & {
  Component: NextPageWithLayout
  cms: any
  privacyPolicyPageUrl: string
  gtcPageUrl: string
}

function MyApp(props: Props) {
  const { Component, pageProps, cms, privacyPolicyPageUrl, gtcPageUrl } = props
  const router = useRouter()

  const getLayout = Component.getLayout || ((page) => page)
  const overrideLayout = !!Component.getLayout

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return (
    <AppTrackingProvider>
      <ReduxProvider store={store}>
        <SSRProvider>
          <SWRConfig>
            <MuiThemeProvider theme={muiTheme}>
              <AppConfigProvider config={appConfig}>
                <PrivacyPolicyPageUrlContext.Provider value={privacyPolicyPageUrl}>
                  <CmsProvider cms={cms}>
                    <GtcPageUrlContext.Provider value={gtcPageUrl}>
                      <Head>
                        <AppHeadContent />
                      </Head>
                      {gtag.GA_TRACKING_ID && (
                        <>
                          <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />
                          <Script
                            id={'ga4'}
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                              __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${gtag.GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                      });
                    `,
                            }}
                          />
                        </>
                      )}
                      <IconSvgSprite />
                      {overrideLayout ? (
                        getLayout(<Component {...pageProps} />)
                      ) : (
                        <DefaultLayout cms={cms}>
                          <Component {...pageProps} />
                        </DefaultLayout>
                      )}
                    </GtcPageUrlContext.Provider>
                  </CmsProvider>
                </PrivacyPolicyPageUrlContext.Provider>
              </AppConfigProvider>
            </MuiThemeProvider>
          </SWRConfig>
        </SSRProvider>
        {/*<style global jsx>*/}
        {/*  {`*/}
        {/*    nextjs-portal {*/}
        {/*      display: none;*/}
        {/*    }*/}
        {/*    body {*/}
        {/*      padding-right: 0 !important;*/}
        {/*      overflow: auto !important;*/}
        {/*    }*/}
        {/*  `}*/}
        {/*</style>*/}
      </ReduxProvider>
    </AppTrackingProvider>
  )
}

// language=GraphQL
const MAIN_NAV_QUERY = `query Query($id: ID!) {
  tenant: tenant(id: $id) {
    cms {
      config {
        contactBlockSettings {
          slogan
          text
          email
          phone
          openingHours1
          openingHours2
        }
        socialMediaSettings {
          facebookUrl
          instagramUrl
        }
        gtcPage {
          id
          seoSettings {
            urlSlug
          }
        }
        privacyPolicyPage {
          id
          seoSettings {
            urlSlug
          }
        }
      }
      categories {
        name
        seoSettings {
          urlSlug
        }
        pageIds
        pages {
          id
          name
          onlineStatus
          seoSettings {
            urlSlug
          }
        }
      }
    }
  }
}`

const getGlobalPageUrl = (cms: tenantCmsObject, pageName: 'privacyPolicyPage' | 'gtcPage') => {
  if (cms.config[pageName]?.id) {
    const pageId = cms.config[pageName].id
    const pageSlug = cms.config[pageName].seoSettings.urlSlug
    const category = cms.categories.find((category) => {
      // @ts-ignore
      return category.pageIds.includes(pageId)
    })
    if (category) {
      const categorySlug = category.seoSettings.urlSlug
      return `/${categorySlug}/${pageSlug}`
    }
  }
  return '/'
}

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext)
  const data = await graphQLClient.request(MAIN_NAV_QUERY, { id: process.env.NEXT_PUBLIC_TENANT_ID })
  const privacyPolicyPageUrl = getGlobalPageUrl(data.tenant.cms, 'privacyPolicyPage')
  const gtcPageUrl = getGlobalPageUrl(data.tenant.cms, 'gtcPage')

  // delete unnecessary data
  delete data.tenant.cms.config.privacyPolicyPage
  delete data.tenant.cms.config.gtcPage

  return {
    ...appProps,
    cms: data.tenant.cms,
    privacyPolicyPageUrl: privacyPolicyPageUrl,
    gtcPageUrl: gtcPageUrl,
  }
}

// @ts-ignore
export default appWithTranslation(MyApp)
