import { createContext, ReactNode } from 'react'
import { tenantCmsObject } from '@/entities/interfaces'

export type CMSContextType = {
  cms: tenantCmsObject | undefined
  getPageUrl: (pageId: number | undefined) => string
}

export const CmsContext = createContext<CMSContextType | undefined>(undefined)

interface Props {
  cms: tenantCmsObject
  children: ReactNode
}

const CmsProvider = ({ cms, children }: Props) => {
  const getPageUrl = (pageId: number | undefined) => {
    if (!cms || !pageId) return ''

    const category = cms.categories.find((category) => category.pageIds?.includes(pageId))
    const categorySlug = category?.seoSettings.urlSlug || 'pages'

    const page = cms.categories.flatMap((category) => category.pages).find((page) => page.id === pageId)
    const pageSlug = page?.seoSettings.urlSlug || pageId

    return `/${categorySlug}/${pageSlug}`
  }

  return <CmsContext.Provider value={{ cms, getPageUrl }}>{children}</CmsContext.Provider>
}

export default CmsProvider
