const featuresConfig = {
  features: {
    esim: {
      active: false,
    },
    bookingSummary: {
      showBoarding: true,
    },
  },
}

export default featuresConfig
