import React, { useEffect } from 'react'
import { useTracking } from '@/integrations/tracking/useTracking'
import { useRouter } from 'next/router'

interface Props {
  children: React.ReactNode
}

const AppTrackingProviderInner = ({ children }: Props) => {
  const { send } = useTracking()
  const router = useRouter()

  let clickStart: number | null = null // Stores the timestamp for click start
  let clickTimeout: NodeJS.Timeout | null = null // Timeout for single click delay if double-click occurs

  const extractTrackingString = (trackingString: string): { category: string; object: string; action: string } => {
    const [category, object, action] = trackingString.split(':')
    return { category, object, action }
  }

  const getTrackingAttributes = (element: HTMLElement): Record<string, string> => {
    const attributes: Record<string, string> = {}
    Array.from(element.attributes).forEach((attr) => {
      if (attr.name.startsWith('data-tr-') && !['data-tr-click', 'data-tr-longclick', 'data-tr-dblclick'].includes(attr.name)) {
        const key = attr.name.replace('data-tr-', '')
        attributes[key] = attr.value
      }
    })
    return attributes
  }

  useEffect(() => {
    const findTrackingElement = (startElement: HTMLElement, maxLevels: number = 5): HTMLElement | null => {
      let element: HTMLElement | null = startElement
      let level = 0

      // Search up the DOM tree to find an element with tracking attributes
      while (element && level < maxLevels) {
        if (element.hasAttribute('data-tr-click') || element.hasAttribute('data-tr-longclick') || element.hasAttribute('data-tr-dblclick')) {
          return element
        }
        element = element.parentElement
        level++
      }
      return null
    }

    const handleMouseDown = (event: MouseEvent) => {
      const target = findTrackingElement(event.target as HTMLElement)
      if (!target) return

      // Start the click timer
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clickStart = Date.now()
    }

    const handleMouseUp = (event: MouseEvent) => {
      const target = findTrackingElement(event.target as HTMLElement)
      if (!target) return

      const clickDuration = clickStart ? Date.now() - clickStart : 0
      clickStart = null // Reset the click start time

      // Ignore if a double-click will be handled
      if (clickTimeout) return

      if (clickDuration < 300 && target.hasAttribute('data-tr-click')) {
        // Set a timeout to delay single-click tracking to wait for potential double-click
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clickTimeout = setTimeout(() => {
          const trackingString = target.getAttribute('data-tr-click')!
          const trackingData = extractTrackingString(trackingString)
          const additionalData = getTrackingAttributes(target)

          send.genericEvent({
            ...trackingData,
            data: additionalData,
          })

          clickTimeout = null
        }, 300) // Adjust delay as needed for double-click detection
      } else if (clickDuration >= 500 && target.hasAttribute('data-tr-longclick')) {
        // Track as long click if duration is 500 ms or more
        const trackingString = target.getAttribute('data-tr-longclick')!
        const trackingData = extractTrackingString(trackingString)
        const additionalData = getTrackingAttributes(target)

        send.genericEvent({
          ...trackingData,
          data: additionalData,
        })
      }
    }

    const handleDoubleClick = (event: MouseEvent) => {
      const target = findTrackingElement(event.target as HTMLElement)
      if (!target || !target.hasAttribute('data-tr-dblclick')) return

      // Cancel single-click timeout if double-click occurs
      if (clickTimeout) {
        clearTimeout(clickTimeout)
        clickTimeout = null
      }

      // Track the double-click event
      const trackingString = target.getAttribute('data-tr-dblclick')!
      const trackingData = extractTrackingString(trackingString)
      const additionalData = getTrackingAttributes(target)

      send.genericEvent({
        ...trackingData,
        data: additionalData,
      })
    }

    // Add event listeners for mousedown, mouseup, and double-click
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('dblclick', handleDoubleClick)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('dblclick', handleDoubleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Function to retrieve dynamic data-tr-* attributes
    const getTrackingAttributes = (element: HTMLElement): Record<string, string> => {
      const attributes: Record<string, string> = {}
      Array.from(element.attributes).forEach((attr) => {
        if (attr.name.startsWith('data-tr-') && attr.name !== 'data-tr-click') {
          // Add attribute, key without "data-tr-" prefix
          const key = attr.name.replace('data-tr-', '')
          attributes[key] = attr.value
        }
      })
      return attributes
    }

    // Intersection Observer to trigger "view" events
    let observer: IntersectionObserver | null = null

    const observeElements = (elements: NodeListOf<HTMLElement>) => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const target = entry.target as HTMLElement
              const trackingString = target.getAttribute('data-tr-view')
              if (trackingString) {
                const trackingData = extractTrackingString(trackingString)
                const additionalData = getTrackingAttributes(target)

                // Send "view" event
                send.genericEvent({
                  ...trackingData,
                  data: additionalData,
                })

                // Unobserve the element after first view
                observer?.unobserve(target)
              }
            }
          })
        },
        {
          threshold: 0.6, // Trigger when at least 60% of the element is visible
        }
      )

      elements.forEach((el) => observer?.observe(el))
    }

    // Function to initialize observers for elements with data-tr-view
    const initViewTracking = () => {
      // Clear previous observer if exists
      if (observer) {
        observer.disconnect()
      }

      // Set up observer for elements with data-tr-view attribute
      const viewElements = document.querySelectorAll('[data-tr-view]')
      if (viewElements.length > 0) {
        observeElements(viewElements as NodeListOf<HTMLElement>)
      }
    }

    // Initialize view tracking on first load
    initViewTracking()

    // Re-initialize view tracking on route change
    router.events.on('routeChangeComplete', initViewTracking)

    // Clean-up function
    return () => {
      router.events.off('routeChangeComplete', initViewTracking)
      if (observer) {
        observer.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return <>{children}</>
}

export default AppTrackingProviderInner
