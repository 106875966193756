import { bookingFunnelEvents } from '@/integrations/tracking/events/booking/funnel'
import trackers from '@/integrations/tracking/trackers'
import { TrackingEventArgs, TrackingIdentifyArgs } from '@/integrations/tracking/types'

export const sendTrackingEvent = {
  ...bookingFunnelEvents,
  genericEvent: ({ category, object, action, data }: TrackingEventArgs) => {
    trackers.forEach((tracker) => {
      tracker.sendEvent({
        category: category,
        object: object,
        action: action,
        data,
      })
    })
  },
  identify: (data: TrackingIdentifyArgs) => {
    trackers.forEach((tracker) => {
      tracker.identify(data)
    })
  },
}
export const useTracking = () => {
  return { send: sendTrackingEvent }
}
